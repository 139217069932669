import useTranslation from 'next-translate/useTranslation';

import styles from './Footer.module.scss';

// import Button, { ButtonType } from '@/dls/Button/Button';
import QuranTextLogo from '@/icons/quran-text-logo.svg';
// import { makeDonateUrl } from '@/utils/apiPaths';
// import { logEvent } from '@/utils/eventLogger';

const TitleAndDescription = () => {
  const { t } = useTranslation('common');
  // const onDonateClicked = () => {
  //   logEvent('donate_button_clicked', {
  //     source: 'sidebar_banner',
  //   });
  // };

  return (
    <div className={styles.titleAndDescriptionContainer}>
      <div className={styles.headingContainer}>
        <div className={styles.iconContainer}>
          <QuranTextLogo className={styles.icon} />
        </div>
        <div className={styles.title}>{t('footer.title')}</div>
      </div>
      <p className={styles.description}>{t('footer.description')}</p>
      <p>
        {/* <Button
          onClick={onDonateClicked}
          isNewTab
          href={makeDonateUrl(true)}
          type={ButtonType.Warning}
          className={styles.cta}
        >
          {t('donate')}
        </Button> */}
      </p>
    </div>
  );
};

export default TitleAndDescription;
